import { Link } from 'react-router-dom';

import { useFetch } from '../../hook/useFetch';

import ComumCard from '../../cards/ComumCard/ComumCard';
import { removerStorage } from '../../services/Services';
import { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';

function Inicio() {

    const { setEstado, setCidade } = useAuth();

    const { data: anuncios } = useFetch(`${process.env.REACT_APP_API_URL}/anuncios/filter`);

    useEffect(() => {
        removerStorage('uf');
        setEstado('');
        setCidade('');
    }, []);

    return (
        <>
            <div className="p-16 mt-5 w-full text-center sm:text-start">
                <div className="text-4xl font-bold tracking-tight text-gray-800 sm:text-5xl">
                    <h1>Acompanhantes de</h1>
                    <span className='text-pink-500'>TODO BRASIL</span>
                </div>
                <p className="mt-4 text-base text-gray-500 sm:w-2/5">
                    Encontre a sua melhor companhia aqui na GarotasHub. Perfis selecionados para momentos de prazer e diversão em todo o Brasil.
                </p>
                <div className='flex justify-center sm:justify-start mt-4'>
                    <a
                        href="#"
                        className="mr-2 rounded-md border border-pink-500 px-8 py-3 text-center font-medium text-pink-500 hover:text-white hover:bg-pink-500"
                    >
                        Anuncie
                    </a>
                    <a
                        href="#"
                        className="rounded-md border border-gray-500 px-8 py-3 text-center font-medium text-gray-500 hover:text-white hover:bg-gray-500"
                    >
                        Registre-se
                    </a>
                </div>
            </div>

            {anuncios && anuncios.some(x => x.estado === 'ES') &&
                <div className='p-4 bg-white shadow'>
                    <h1 className='mt-4 text-center font-bold tracking-tight text-gray-700 sm:text-3xl'>Acompanhantes do <span className='text-pink-500'>Espirito Santo</span></h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 p-8 mb-6">
                        {anuncios.filter(x => x.estado === 'ES').slice(0, 5).map((anuncio, index) => (
                            <ComumCard key={index} {...anuncio} />
                        ))}
                    </div>
                    <div className='text-center'>
                        <Link
                            to="/acompanhantes/es"
                            className="inline-block rounded-md bg-pink-500 px-8 py-3 font-medium text-white hover:bg-pink-400"
                        >
                            Ver todos acompanhantes do Espírito Santo
                        </Link>
                    </div>
                </div>
            }

            <div className='p-10'>
                teste

            </div>

            {anuncios && anuncios.some(x => x.estado === 'RJ') &&
                <div className='p-4 bg-white'>
                    <h1 className='text-center font-bold tracking-tight text-gray-700 sm:text-3xl'>Acompanhantes do <span className='text-pink-500'>Espirito Santo</span></h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 p-8 mb-6">
                        {anuncios.filter(x => x.estado === 'RJ').slice(0, 5).map((anuncio, index) => (
                            <ComumCard key={index} {...anuncio} />
                        ))}
                    </div>
                    <div className='text-center'>
                        <Link
                            to="/acompanhantes/rj"
                            className="inline-block rounded-md bg-pink-500 px-8 py-3 font-medium text-white hover:bg-pink-400"
                        >
                            Ver todos acompanhantes do Rio de Janeiro
                        </Link>
                    </div>
                </div>
            }

            {anuncios && anuncios.some(x => x.estado === 'SP') &&
                <div className='p-4 bg-white'>
                    <h1 className='text-center font-bold tracking-tight text-gray-700 sm:text-3xl'>Acompanhantes do <span className='text-pink-500'>Espirito Santo</span></h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 p-8 mb-6">
                        {anuncios.filter(x => x.estado === 'SP').slice(0, 5).map((anuncio, index) => (
                            <ComumCard key={index} {...anuncio} />
                        ))}
                    </div>
                    <div className='text-center'>
                        <Link
                            to="/acompanhantes/sp"
                            className="inline-block rounded-md bg-pink-500 px-8 py-3 font-medium text-white hover:bg-pink-400"
                        >
                            Ver todos acompanhantes de São Paulo
                        </Link>
                    </div>
                </div>
            }

            {anuncios && anuncios.some(x => x.estado === 'MG') &&
                <div className='p-4 bg-white'>
                    <h1 className='text-center font-bold tracking-tight text-gray-700 sm:text-3xl'>Acompanhantes do <span className='text-pink-500'>Espirito Santo</span></h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 p-8 mb-6">
                        {anuncios.filter(x => x.estado === 'MG').slice(0, 5).map((anuncio, index) => (
                            <ComumCard key={index} {...anuncio} />
                        ))}
                    </div>
                    <div className='text-center'>
                        <Link
                            to="/acompanhantes/mg"
                            className="inline-block rounded-md bg-pink-500 px-8 py-3 font-medium text-white hover:bg-pink-400"
                        >
                            Ver todos acompanhantes de Minas Gerais
                        </Link>
                    </div>
                </div>
            }

        </>
    );
}

export default Inicio;