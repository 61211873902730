import axios from "axios"
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthConfig = () => ({
    headers: {
        Authorization: `Bearer ${obterStorage('token')}`
    }
})

// Autenticar usuario
export const auth = async (email, password) => {
    return await axios.post(`${API_URL}/auth/login`, { email, password })
        .then(response => {
            addStorage('token', response.data.token);
            toast.success('Iniciando seção');
        }).catch(error => {
            toast.error('Acesso negado')
            console.log(error)
        })
}

// Recuperar usuario via token
export const getUserToken = async () => {
    return await axios.get(`${API_URL}/usuarios/token`, getAuthConfig());
}

// Cadastrar novo usuario
export const registerUser = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/usuarios/cadastrar`, data);
        toast.success('Conta criada com sucesso !');
        return response.data;
    } catch(error) {
        if (Array.isArray(error.response.data)) {
            error.response.data.map((error => toast.warn(error)));
        } else {
            toast.error(error.response.data.error);
        }
        console.log(error)
    }
}

// Atualizar usuario
export const userUpdate = async (data) => {
    try {
        const response = await axios.put(`${API_URL}/usuarios/${data.id}`, data, getAuthConfig());
        toast.success('Atualizado com sucesso');
        return response.data;
    } catch(error) {
        if (Array.isArray(error.response.data)) {
            error.response.data.map((error => toast.warn(error)));
        } else {
            toast.error(error.response.data.error);
        }       
    }
}

// GetAnuncios por filtro
export const anunciosFilter = async (data) => {
    try {

        if (data.uf !== undefined && data.estado !== '' && data.cidade !== '')  {
            const response = await axios.get(`${API_URL}/anuncios/filter?estado=${data.estado}&cidade=${data.cidade}`);
            return response.data;
        }

        if (data.uf !== undefined) {
            const response = await axios.get(`${API_URL}/anuncios/filter?estado=${data.uf}`);
            return response.data;
        }
        
        const response = await axios.get(`${API_URL}/anuncios/filter`);
        return response.data;

    } catch {
        console.error('error')
    }
}

// Criar novo anuncio
export const criarAnuncio = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/anuncios`, data);
        toast.success('1/3')
        return response.data;
    } catch {
        
    }
}

// Atualizar anuncio
export const anuncioUpdate = async (data) => {
    try {
        const response = await axios.put(`${API_URL}/anuncios/${data.id}`, data, getAuthConfig());
        toast.success('Atualizado com sucesso');
        return response.data;
    } catch {
        toast.error('Error ao atualizar')
    }
}

// Seguir/Deixar anuncio
export const seguirAnuncio = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/util/follow`, data);
        return response.data;
    } catch {
        console.log('error')
    }
}

// Email - Recuperacao de senha
export const recuperarSenha = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/email/recuperar/${data}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

// Adicionar foto de perfil
export const addFotoPerfil = async (data) => {
    try {
        await axios.post(`${API_URL}/imagens/perfil`, data);
        toast.success('2/3')
    } catch (error) {
        console.error('Erro ao enviar foto:', error);
    }
};

// Adicionar album de fotos
export const addAlbum = async (data) => {
    try {
        await axios.post(`${API_URL}/imagens/album`, data);
        toast.success('3/3')
    } catch (error) {
        console.error('Erro ao enviar foto:', error);
    }
};

// Adicionar item ao LocalStorage
export const addStorage = (chave, valor) => {
    localStorage.setItem(chave, JSON.stringify(valor));
}

// Obter item do LocalStorage
export const obterStorage = (chave) => {
    const item = localStorage.getItem(chave);
    return JSON.parse(item);
}

// Remover item do LocalStorage
export const removerStorage = (chave) => {
    localStorage.removeItem(chave);
}

//ADMIN
//
export const getAnalise = async () => {
    try {
        const response = await axios.get(`${API_URL}/anuncios/analise`);
        return response.data;
    } catch {
        console.log('error')
    }
}

export const getAllAnuncios = async () => {
    try {
        const response = await axios.get(`${API_URL}/anuncios`);
        return response.data;
    } catch {
        console.log('error')
    }
}