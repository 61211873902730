import { createContext, useContext, useEffect, useState } from "react";

//service
import AuthService from "../app/service/AuthService";

import { toast } from "react-toastify";
import { auth, getUserToken, obterStorage } from "../services/Services";

import { isExpired } from "react-jwt";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [usuario, setUsuario] = useState(null);
    const [loading, setLoading] = useState(true);

    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');

    useEffect(() => {
        refresh();
    }, []);

    const login = async (username, password) => {
        await auth(username, password);
        refresh();
    }

    const logout = () => {
        toast.success('Encerrando seção');
        AuthService.logoutStorage();
        setUsuario(null);
    }

    const refresh = async () => {
        const uf = obterStorage('uf')
        if (uf) {
            setEstado(uf)
        }

        const token = obterStorage('token');
        if (token) {
            const user = await getUserToken();
            setUsuario(user.data);
            setLoading(false);
        }
        
        setLoading(false)
    }

    const isAuth = () => {
        const token = obterStorage('token')
        if (!token) {
            return false;
        }
        if (isExpired(token)) {
            logout()
            return false;
        }
        return true;
    }

    return (
        <AuthContext.Provider value={{
            usuario, setUsuario,
            estado, setEstado,
            cidade, setCidade,
            loading,
            isAuth,
            login,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};