export default class LocalStorageService {

    static addItem(key, valor) {
        localStorage.setItem(key, JSON.stringify(valor));
    }

    static obterItem(key) {
        const item = localStorage.getItem(key);
        return JSON.parse(item);
    }

    static removerItem(key) {
        localStorage.removeItem(key);
    }
}