import ApiService from "./ApiService";
import LocalStorageService from "./LocalStorageService";

export const TOKEN = 'token';
export const USER = 'user';

export default class AuthService extends ApiService {

    constructor() {
        super('/auth')
    }

    autenticar(credenciais) {
        return this.post('/login', credenciais);
    }

    static loginStorage(user, token) {
        LocalStorageService.addItem(TOKEN, token);
        LocalStorageService.addItem(USER, user);
    }
    
    static logoutStorage() {
        LocalStorageService.removerItem(TOKEN);
        LocalStorageService.removerItem(USER);
    }
}