//react
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

//layout
import Navbar from '../layout/Navbar/Navbar';

//paginas
import Inicio from '../paginas/Inicio/Inicio';
import Anuncie from '../paginas/Anuncie/Anuncie';
import Cam from '../paginas/Cam/Cam';
import Login from '../paginas/Login/Login';
import Cadastrar from '../paginas/Cadastrar/Cadastrar';
import Profile from '../paginas/Profile/Profile';
import Dashboard from '../paginas/Dashboard/Dashboard';

//paginas-buscar
import Acompanhantes from '../paginas/Acompanhantes/Acompanhantes';

//util
import ScrollToTop from '../util/ScrollToTop';
import NotFound from '../util/NotFound';
import Loading from '../util/Loading';
import Teste from '../util/Teste';
import Testee from '../util/Testee';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';
import { Footer } from '../layout/Footer/Footer';

import { TesteB } from '../util/TesteB'
import Profile1 from '../util/Profile';

function App() {

  const { loading, isAuth } = useContext(AuthContext);

  const PrivateRoute = ({ children }) => {
    return isAuth() ? children : <Navigate to="/login" />;
  };

  if (true) {
    return (
      <body class="bg-pink-500 h-screen flex items-center justify-center">
        <div class="text-center">
          <svg class="animate-spin h-10 w-10 text-white mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <p class="text-white text-lg font-medium">Carregando...</p>
          <p class="text-white text-lg">Site em atualização</p>
        </div>
      </body>
    )
  }

  return (
    <BrowserRouter>
      <ToastContainer
        closeOnClick
      />
      <ScrollToTop />
      <Navbar />
      <TesteB />
      <Routes>
        <Route path="/teste" element={<Teste />} />
        <Route path="/testee" element={<Testee />} />
        <Route path="/prof" element={<Profile1 />} />
        <Route path="*" element={<NotFound />} />
        <Route path='/' element={<Inicio />} />
        <Route path='/garotas-cam' element={<Cam />} />
        <Route path='/acompanhantes' element={<Acompanhantes />} />
        <Route path='/anuncie' element={<Anuncie />} />
        <Route path='/login' element={<Login />} />
        <Route path='/cadastrar' element={<Cadastrar />} />

        <Route path='acompanhantes/:uf/:link' element={<Profile />} />
        <Route path='acompanhantes/:uf?' element={<Acompanhantes />} />
        <Route path='acompanhantes/:uf?/:cidade?' element={<Acompanhantes />} />

        <Route path="/dashboard/:page?" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
        />
        <Route path="/dashboard/:page/:id?" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
        />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;